type Props = {
  color: string;
  width?: string;
  height?: string;
};

const ArrowLongLeft = ({ color, width = '16', height = '16' }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43_217)">
        <path
          d="M6.66667 3.28645L1.95312 8L6.66667 12.7135L7.66667 11.7135L4.61979 8.66666H14V7.33333H4.61979L7.66667 4.28645L6.66667 3.28645Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_43_217">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ArrowLongLeft };
