type Props = {
  color: string;
  width?: string;
  height?: string;
};

const ArrowLongRight = ({ color, width = '100%', height = '100%' }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43_220)">
        <path
          d="M9.33333 3.28645L8.33333 4.28645L11.3802 7.33333H2V8.66666H11.3802L8.33333 11.7135L9.33333 12.7135L14.0469 8L9.33333 3.28645Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_43_220">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ArrowLongRight };
